import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MondayService {

  constructor(private requestService: RequestService) { }

  async getAllAgents(queryParams: string = ''): Promise<void> {
    return new Promise((resolve, reject) => {
      let requestType = 'agencyContacts';
      let type = this.requestService.currentUser.type;
      let email = this.requestService.currentUser.email;
      let typeEnum = type == '0' ? 'admin' : type == '1' ? 'agency' : 'agent';

      this.requestService.postRequest('monday', '', {
        reqType: requestType,
        userType: typeEnum,
        email: email,
        queryParams: queryParams,
      }, (data, error) => {
        if (data) {
          resolve(data);
        }
        else if (error) {
          reject(error);
        }
      });
    });
  }

  async getAgentByEmail(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let requestType = 'byEmail';

      this.requestService.postRequest('monday', '', {
        reqType: requestType,
        userType: 'admin',
        email: email,
      }, (data, error) => {
        if (data) {
          resolve(data);
        }
        else if (error) {
          reject(error);
        }
      });
    });
  }

  async getAgencies(): Promise<void> {
    return new Promise((resolve, reject) => {
      let requestType = 'agencies';
      let type = this.requestService.currentUser.type;
      let email = this.requestService.currentUser.email;
      let typeEnum = type == '0' ? 'admin' : type == '1' ? 'agency' : 'agent';

      this.requestService.postRequest('monday', '', {
        reqType: requestType,
        userType: typeEnum,
        email: email,
      }, (data, error) => {
        if (data) {
          resolve(data);
        }
        else if (error) {
          reject(error);
        }
      });
    });
  }

  async getAdmins(reqType: string = 'adminContacts', agencyId: string = ''): Promise<void> {
    return new Promise((resolve, reject) => {
      let email = this.requestService.currentUser.email;

      let postData: any = {
        reqType: reqType,
        userType: 'admin',
        email: email,
      };

      if (agencyId) {
        postData.agencyId = agencyId;
      }

      this.requestService.postRequest('monday', '', postData, (data, error) => {
        if (data) {
          resolve(data);
        }
        else if (error) {
          reject(error);
        }
      });
    });
  }
}
